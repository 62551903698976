import { UnsupportedChainWarning } from '@/components/features/UnsupportedChainWarning/UnsupportedChainWarning';
import { Footer } from '@/components/features/layout/Footer';
import { StatusBanner } from '@/components/features/layout/StatusBanner';
import { Flex } from '@chakra-ui/react';
import { type PropsWithChildren } from 'react';
import DevCompanionDrawer from '../DevCompanion/DevCompanionDrawer';
import { StickyNavigation } from './Header/Components/StickyNavigation';
import MainWrapper from './MainWrapper';

type Props = {
  hasBottomSpacing?: boolean;
  hasFooter?: boolean;
  preview?: boolean;
  renderWithoutLayout?: boolean;
};

export const BaseLayout = ({
  hasBottomSpacing = true,
  children,
  renderWithoutLayout,
}: PropsWithChildren<Props>) => (
  <Flex flexDirection="column">
    <StatusBanner />
    {!renderWithoutLayout && <StickyNavigation />}
    <MainWrapper renderWithoutLayout={renderWithoutLayout} hasBottomSpacing={hasBottomSpacing}>
      {children}
    </MainWrapper>
    {!renderWithoutLayout && (
      <>
        <Footer position="sticky" bottom={0} zIndex="behind" />
        <UnsupportedChainWarning position="sticky" bottom={0} zIndex="header.inFront" />
      </>
    )}
    <DevCompanionDrawer position="fixed" zIndex="header.inFront" top={2} left={2} />
  </Flex>
);
