import { RESERVOIR_ID_REGEX } from '@/utils/regex';
import { Address } from 'viem';
import { ErrorType } from 'viem/_types/errors/utils';

export enum ReservoirAction {
  Buy = 'buy',
  List = 'list',
  Bid = 'bid',
  AcceptBid = 'acceptBid',
  CancelListing = 'cancelListing',
  CancelBid = 'cancelBid',
  EditListing = 'editListing',
  EditBid = 'editBid',
  // Mint & Sweep
  Collect = 'collect',
  Transfer = 'transfer',
}

export type IsAddressReservoirErrorType = ErrorType;

export const isReservoirId = (address: string): address is Address => {
  return RESERVOIR_ID_REGEX.test(address);
};

export const extractAddressFromReservoirId = (input?: string) =>
  input?.match(/^0x[a-fA-F0-9]{40}/)?.[0] || null;
