import { TemplateType } from '@/components/features/Editor/utils';
import { PublishType } from '@/server/api/routers/utils';
import QueryString from 'qs';
import { Address, checksumAddress } from 'viem';

const withQueryParams = (route: string, queryParams?: Record<string, string>): string => {
  if (!queryParams) return route;
  return `${route}?${QueryString.stringify(queryParams)}`;
};

export const routes = {
  home: (chainPrefix: string) => `/${chainPrefix}`,
  search: '/search',
  insights: (chainPrefix: string) => `/${chainPrefix}/insights`,
  profile: (address: string, chainPrefix: string, queryParams?: Record<string, string>) => {
    const query = QueryString.stringify({ chain: chainPrefix, ...queryParams });
    return `/account/${checksumAddress(address as Address)}?${query}`;
  },
  account: {
    collections: {
      overview: '/account/collections',
      create: '/account/collections/create',
      index: (address: string, chainPrefix: string) =>
        `/account/collections/${chainPrefix}/${checksumAddress(address as Address)}`,
      card: (address: string, chainPrefix: string) =>
        `/account/collections/${chainPrefix}/${checksumAddress(address as Address)}/card`,
      templatePreview: (address: string, chainPrefix: string, templateType?: TemplateType) =>
        withQueryParams(
          `/account/collections/${chainPrefix}/${checksumAddress(
            address as Address,
          )}/template-preview`,
          templateType ? { templateType } : undefined,
        ),
      editor: (address: string, chainPrefix: string) =>
        `/account/collections/${chainPrefix}/${checksumAddress(address as Address)}/editor`,
      template: (address: string, chainPrefix: string) =>
        `/account/collections/${chainPrefix}/${checksumAddress(address as Address)}/template`,
      details: (address: string, chainPrefix: string) =>
        `/account/collections/${chainPrefix}/${checksumAddress(address as Address)}/details`,
      option: (address: string, chainPrefix: string) =>
        `/account/collections/${chainPrefix}/${checksumAddress(address as Address)}/option`,
      publish: (address: string, chainPrefix: string, type: PublishType) =>
        withQueryParams(
          `/account/collections/${chainPrefix}/${checksumAddress(address as Address)}/publish`,
          type ? { type } : undefined,
        ),
    },
    games: {
      index: (slug: string, chainPrefix: string) => `/account/games/${chainPrefix}/${slug}`,
      collections: (slug: string, chainPrefix: string) =>
        `/account/games/${chainPrefix}/${slug}/collections`,
      details: (slug: string, chainPrefix: string) =>
        `/account/games/${chainPrefix}/${slug}/details`,
      template: (slug: string, chainPrefix: string) =>
        `/account/games/${chainPrefix}/${slug}/template`,
      templatePreview: (slug: string, chainPrefix: string, templateType?: TemplateType) =>
        withQueryParams(
          `/account/games/${chainPrefix}/${slug}/template-preview`,
          templateType ? { templateType } : undefined,
        ),
      editor: (slug: string, chainPrefix: string) => `/account/games/${chainPrefix}/${slug}/editor`,
      publish: (slug: string, chainPrefix: string) =>
        `/account/games/${chainPrefix}/${slug}/publish`,
    },
  },
  collections: {
    overview: (chainPrefix: string) => `/${chainPrefix}/collections`,
    detail: (address: string, chainPrefix: string, queryParams?: Record<string, string>) =>
      withQueryParams(
        `/${chainPrefix}/collection/${checksumAddress(address as Address)}`,
        queryParams,
      ),
  },
  games: {
    overview: (chainPrefix: string) => `/${chainPrefix}/games`,
    detail: (slug: string, chainPrefix: string) => `/${chainPrefix}/game/${slug}`,
  },
  nft: {
    detail: (collectionAddress: string, tokenId: string, chainPrefix: string) =>
      `/${chainPrefix}/nft/${checksumAddress(collectionAddress as Address)}/${tokenId}`,
    // Required for Reservoir components
    raw: (chainPrefix: string) => `/${chainPrefix}/nft/` + '${contract}/${tokenId}',
  },
  article: {
    termsAndConditions: '/article/terms-and-conditions',
    privacyPolicy: '/article/privacy-policy',
    fees: '/article/fees',
    about: '/article/about',
    security: '/article/security',
    getBySlug: (slug: string) => `/article/${slug}`,
    getBySlugAndType: (type: string, slug: string) => `/article/${type}/${slug}`,
  },
  support: '/support',
  external: {
    swap: 'https://swap.onbeam.com/',
    docs: 'https://docs.onbeam.com/',
    helpCenter: 'https://support.onbeam.com/hc/en-us/categories/14553028847901-Sphere-Marketplace',
    collectionDocs: 'https://docs.onbeam.com/service/sphere/publish-collections',
    gameDocs: 'https://docs.onbeam.com/service/sphere/creating-games',
    request: 'https://support.onbeam.com/hc/en-us/requests/new',
  },
};
