import { StatusBannerText } from '@/components/features/layout/StatusBanner/StatusBannerText';
import { useStatusBanner } from '@/components/features/layout/StatusBanner/useStatusBanner';
import { StatusBanner as StatusBannerProps } from '@/lib/graphql/contentful/types.contentful.generated';
import { Box, Container, IconButton } from '@chakra-ui/react';
import { CloseNormalIcon } from '@sphere/icons';
import { rem } from 'polished';
import { useEffect } from 'react';
import { useAsync, useLocalStorage, useMeasure } from 'react-use';
import { Status, StatusIcon, statusColorMap } from './utils';

export const StatusBanner = () => {
  const [containerRef, { height }] = useMeasure<HTMLDivElement>();
  const [dismissedTimestamp, setDismissedTimestamp] = useLocalStorage(
    'status-banner-dismissed-timestamp',
  );

  const { value } = useAsync(async () => {
    const res = await fetch('/api/contentful/status-banner');
    const data = await res.json();
    return data as Pick<StatusBannerProps, 'status' | 'text' | 'sys'>;
  }, []);

  const { status: initialStatus, text, sys } = value || {};
  const updatedAt = new Date(sys?.publishedAt).getTime().toString();

  useEffect(() => {
    useStatusBanner.setState(() => ({ isVisible: initialStatus && text?.json, height }));
  }, [height, initialStatus, text?.json]);

  if (!initialStatus || !text?.json || dismissedTimestamp === updatedAt) return null;

  const status = (initialStatus in Status ? initialStatus : Status.Error) as Status;

  const onDismiss = () => setDismissedTimestamp(updatedAt);

  return (
    <Box ref={containerRef} {...statusColorMap[status]} zIndex="header.menu">
      <Container
        display="flex"
        py="space.16"
        alignItems="center"
        gap={{ base: 'space.10', md: 'space.16' }}
        variant="breakout"
      >
        <StatusIcon status={status} />
        <StatusBannerText text={text} />
        <IconButton
          ml="auto"
          aria-label="Dismiss"
          onClick={onDismiss}
          color="inherit"
          variant="clear"
          icon={<CloseNormalIcon boxSize={rem(14)} />}
          boxSize={rem(32)}
          minW={rem(32)}
          _hover={{ bg: 'gray.4' }}
          isRound
        />
      </Container>
    </Box>
  );
};
