import { Link, Text } from '@chakra-ui/react';
import { Options, documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES, Inline, MARKS } from '@contentful/rich-text-types';
import NextLink from 'next/link';
import { ReactNode } from 'react';

import { StatusBanner } from '@/lib/graphql/contentful/types.contentful.generated';

type LinkResolverArgs = { node: Inline; children: ReactNode };

const resolveHyperlink = ({ node, children }: LinkResolverArgs) => {
  const { uri: href } = node.data;

  if (href.startsWith('http')) {
    return (
      <Link
        href={href}
        fontWeight="medium"
        textDecor="underline"
        _hover={{ textDecor: 'underline', opacity: 0.5 }}
        isExternal
      >
        {children}
      </Link>
    );
  }

  return (
    <NextLink href={href} passHref legacyBehavior>
      <Link
        fontWeight="medium"
        textDecor="underline"
        _hover={{ textDecor: 'underline', opacity: 0.5 }}
      >
        {children}
      </Link>
    </NextLink>
  );
};

const contentfulBaseRichTextOptions = (): Options => ({
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_node, children: ReactNode) => (
      <Text fontSize={{ base: 'sm', md: 'md' }} lineHeight="md">
        {children}
      </Text>
    ),
    [INLINES.HYPERLINK]: (node, children) => resolveHyperlink({ node: node as Inline, children }),
  },
  renderMark: {
    [MARKS.BOLD]: (text: ReactNode) => (
      <Text as="strong" fontWeight="bold">
        {text}
      </Text>
    ),
    [MARKS.ITALIC]: (text: ReactNode) => (
      <Text as="span" fontStyle="italic">
        {text}
      </Text>
    ),
    [MARKS.UNDERLINE]: (text: ReactNode) => (
      <Text as="span" textDecoration="underline">
        {text}
      </Text>
    ),
  },
});

type Props = Pick<StatusBanner, 'text'>;

export const StatusBannerText = ({ text }: Props) => {
  const options = contentfulBaseRichTextOptions();
  return <>{documentToReactComponents(text?.json, options)}</>;
};
