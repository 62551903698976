import { Header } from '@/components/features/layout/Header/Header';
import {
  BoxProps,
  css,
  ResponsiveObject,
  useBreakpointValue,
  useMergeRefs,
  useTheme,
} from '@chakra-ui/react';
import { MotionBox } from '@sphere/ui';
import { MotionProps, useMotionValueEvent, useScroll } from 'framer-motion';
import { useRouter } from 'next/router';
import { remToPx, stripUnit } from 'polished';
import { PropsWithChildren, useMemo, useRef, useState } from 'react';
import { useStickyBox } from 'react-sticky-box';

/**
 * Define all the sticky layout components here so things stay organised
 */
type StickyKey =
  | 'header'
  | 'filter'
  | 'filter-bar'
  | 'sidebar'
  | 'action-bar'
  | 'table-header'
  | 'tabs';

/**
 * We only want numbers in this configuration, could be extended to allow strings for rem values
 */
type DistanceValue = number | string;

/**
 * A distance config is a responsive object or a value, we don't like arrays for simplicity reasons.
 */
type DistanceConfig = ResponsiveObject<DistanceValue> | DistanceValue;

/**
 * A config object per route that contains information about the sticky layout components on that specific route.
 * You need to define the distance from the top of the screen when it gets stuck. For example: the header is 80px in height.
 *
 * You want the 'action-bar' element to show up below the header, so you need to define the action-bar key for your route like so:
 *
 * 'action-bar': 80
 *
 * Please note, it's perfectly fine to define responsive values here, like so:
 *
 * 'action-bar': {
 *   base: 80,
 *   md: 120,
 * }
 *
 * This could potentionally be useful if there are conditional sticky elements above the 'action-bar' element that influence the desired distance from the top of the screen.
 */
const config: Record<string, Partial<Record<StickyKey, DistanceConfig>>> = {
  '/account/[address]': {
    'filter-bar': Header.height,
    'table-header': Header.height + 60 + 9, // + 9 due to the filter-bar being 60px in height + 9 pixels for some padding and a divider.
  },
  '/[chain]/nft/[contract]/[tokenId]': {
    'filter-bar': Header.height,
    'table-header': Header.height + 60 + 9,
  },
  '/[chain]/insights': {
    'filter-bar': Header.height,
    'table-header': Header.height + 60,
  },
  '/[chain]/collection/[contract]': {
    'action-bar': Header.height,
    sidebar: Header.height + 100,
    filter: Header.height + 100,
    'filter-bar': Header.height,
    'table-header': Header.height + 60 + 9,
  },
  '/search': {
    'action-bar': Header.height,
  },
};

type StickyProps = Omit<BoxProps, 'position' | 'top'> & {
  /** a pre-defined key that should exist in the route configuration based on the page you are using it on */
  element: StickyKey;
  /** StyleObject that will get applied if the element is stuck */
  stuck?: BoxProps & MotionProps;
  /** offset for the stuck-styling to be applied */
  offset?: number;
};

export const Sticky = ({
  children,
  element,
  stuck = {},
  offset = 100,
  ...props
}: PropsWithChildren<StickyProps>) => {
  const { route } = useRouter();
  const { scrollY } = useScroll();
  const theme = useTheme();

  const [isStuck, setStuck] = useState<boolean>(false);

  /**
   * The distance mapping based on the route configuration.
   */
  const layout: DistanceConfig = useMemo(() => config[route]?.[element] ?? 0, [route, element]);

  /**
   * The distance mapping based on the route configuration. We either get a value, or fallback to 0.
   */
  const rawConfigValue =
    typeof window !== 'undefined' &&
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useBreakpointValue(typeof layout !== 'object' ? { base: layout } : layout, {
      ssr: false,
      fallback: 'md',
    });

  /**
   * Distance in number value for the stuck calculation
   */
  const distance: number = useMemo(() => {
    if (typeof rawConfigValue === 'string') {
      return Number(stripUnit(remToPx(rawConfigValue)));
    }
    return rawConfigValue || 0;
  }, [rawConfigValue]);

  const ref = useRef<HTMLDivElement>(null);
  const sticky = useStickyBox({ offsetTop: distance, offsetBottom: 20 });
  const refs = useMergeRefs(sticky, ref);

  /**
   * On every scroll event we check whenever the element will be stuck, to apply the stuck styling prop
   */
  useMotionValueEvent(scrollY, 'change', scrollYProgress => {
    if (!ref?.current) return null;
    const elTopDistance = ref.current.getBoundingClientRect().top;

    /** if there is a noffset, we want to hold it against the scroll progress, otherwise simply check the config distance */
    setStuck(elTopDistance === 0 ? scrollYProgress >= offset : elTopDistance <= distance);
  });

  return (
    <MotionBox
      // @ts-ignore
      ref={refs}
      {...props}
      /** We want to apply stuck if the element is in 'stuck' state */
      {...(isStuck && {
        ...css(stuck)(theme),
      })}
    >
      {children}
    </MotionBox>
  );
};
