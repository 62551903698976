import { Link, List, ListItem } from '@chakra-ui/react';
import NextLink from 'next/link';

type Props = {
  links: {
    text: string;
    href: string;
  }[];
};

export const FooterBottomLinks = ({ links }: Props) => {
  return (
    <List
      mt={{ base: 0, md: 'space.48' }}
      display="flex"
      justifyContent="center"
      flexDirection={{ base: 'column', md: 'row' }}
      gap={{ base: 'space.8', md: 'space.48' }}
    >
      {links.map(({ text, href }) => (
        <ListItem key={text} display={{ md: 'inline-block' }}>
          <Link
            href={href}
            as={href.includes('mailto') ? 'a' : NextLink}
            textStyle="footer-link-small"
            textDecoration="none"
            fontSize="xs"
            _hover={{
              opacity: 0.6,
            }}
          >
            {text}
          </Link>
        </ListItem>
      ))}
    </List>
  );
};
