import { FormatCryptoCurrency } from '@/components/primitives';
import { Image } from '@/components/shared/Image';
import { SearchCollectionData } from '@/pages/api/globalSearch';
import optimizeImage from '@/utils/optimizeImage';
import { routes } from '@/utils/routes';
import { Flex, Text } from '@chakra-ui/react';
import { formatAddress, formatNumber } from '@onbeam/utils';
import useTranslation from 'next-translate/useTranslation';
import { rem } from 'polished';
import { useMemo } from 'react';
import { SearchHitBase } from './SearchHitBase';

interface Props {
  focusIndex: number;
  collection: SearchCollectionData;
}

export const SearchHitCollection = ({ collection, focusIndex }: Props) => {
  const { t } = useTranslation('search');

  const collectionImage = useMemo(() => {
    return optimizeImage(collection.image, 250);
  }, [collection.image]);

  return (
    <SearchHitBase
      focusIndex={focusIndex}
      href={routes.collections.detail(collection.collectionId ?? '', collection.chainRoutePrefix)}
      leftRender={
        <Image
          src={collectionImage}
          alt={`${collection.name} logo`}
          width={56}
          height={56}
          boxSize={rem(56)}
          objectFit="cover"
          rounded="full"
        />
      }
      rightRender={
        <Flex justifyContent="space-between" alignItems="center" gap="space.4">
          <div>
            <Text fontSize="md" noOfLines={1}>
              {formatAddress(collection.name)}
            </Text>
            {!!Number(collection.tokenCount) && (
              <Text fontSize="sm" color="gray.5" noOfLines={1}>
                {t('results.collections.token-amount', {
                  count: formatNumber(collection.tokenCount),
                })}
              </Text>
            )}
          </div>
          {!!collection.allTimeVolume && (
            <Flex flexDir="column" alignItems="flex-end" gap="space.2">
              <Text fontSize="xs" color="gray.5" textAlign="right">
                {t('results.collections.labels.volume')}
              </Text>
              <FormatCryptoCurrency
                amount={collection.allTimeVolume}
                decimals={collection.volumeCurrencyDecimals}
                logoHeight={14}
                fontSize="sm"
                logoSrc={collection.darkChainIcon}
              />
            </Flex>
          )}
        </Flex>
      }
    />
  );
};
