import { env } from '@/env.mjs';
import * as Fathom from 'fathom-client';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

/**
 * Fathom is our tracking solution that doesn't require any cookies and thus doesn't need any consent
 * To learn more: https://usefathom.com/
 */
export const FathomScript = () => {
  const router = useRouter();

  useEffect(() => {
    if (!env.NEXT_PUBLIC_HOST_URL || !env.NEXT_PUBLIC_FATHOM_ID) return;

    const { host } = new URL(env.NEXT_PUBLIC_HOST_URL);

    /**
     *  Initialize Fathom when the app loads
     *  Example: yourdomain.com
     *  - Do not include https://
     *  - This must be an exact match of your domain.
     *  - If you're using www. for your domain, make sure you include that here.
     */
    Fathom.load(env.NEXT_PUBLIC_FATHOM_ID, {
      includedDomains: [host],
    });

    const onRouteChangeComplete = () => Fathom.trackPageview();

    // Record a pageview when route changes
    router.events.on('routeChangeComplete', onRouteChangeComplete);

    // Unassign event listener
    return () => {
      router.events.off('routeChangeComplete', onRouteChangeComplete);
    };
  }, [router.events]);

  return null;
};
