import { create } from 'zustand';

type Store = {
  isVisible: boolean;
  height: number;
};

export const useStatusBanner = create<Store>(() => ({
  isVisible: false,
  height: 0,
}));
